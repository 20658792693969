import React, { FunctionComponent, MouseEvent, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon
    from '@mui/icons-material/VisibilityOffOutlined';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const styles = {
    passwordInput: {
        width: '100%',
        marginBottom: '10px',
    },
};

export type PasswordFieldProps = TextFieldProps & {
    tooltip?: string | JSX.Element;
};

const PasswordField: FunctionComponent<
    PasswordFieldProps & TextFieldProps
> = props => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () =>
        setShowPassword((show) => !show)
    ;

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return <TextField
        label="Password"
        id="password"
        sx={ styles.passwordInput }
        { ...props }
        type={ showPassword ? 'text' : 'password' }
        InputProps={{
            endAdornment: <Tooltip
                title={ props.tooltip || `${ showPassword
                    ? 'Hide'
                    : 'Show'
                } Password` }
                disableInteractive
                enterTouchDelay={ 0 }
            >
                <InputAdornment position="end">
                    <IconButton
                        onClick={ handleClickShowPassword }
                        onMouseDown={ handleMouseDownPassword }
                        aria-label={
                            `${ showPassword ? 'hide' : 'show' } password`
                        }
                    >{ showPassword
                        ? <VisibilityOutlinedIcon />
                        : <VisibilityOffOutlinedIcon />
                    }</IconButton>
                </InputAdornment>
            </Tooltip>,
        }}
    />;
};

export default PasswordField;
