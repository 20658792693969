/**
 * Returns true if a given HTML input element was autofilled by browser,
 * false - otherwise.
 *
 * @param {HTMLInputElement} element
 * @return {boolean}
 */
export default function isAutoFilled(element: HTMLInputElement) {
    return [':autofill', ':-webkit-autofill', ':-moz-autofill'].some(
        (selector) => {
            try {
                return element.matches(selector);
            } catch {
                return false;
            }
        },
    );
}
