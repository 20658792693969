export function formatEntityName(entity: string) {
    let entityName = '';

    if (entity.toLowerCase() === 'email') {
        entityName = 'email address';
    }

    if (entity.toLowerCase() === 'phone') {
        entityName = 'phone number';
    }

    return entityName;
}

export const getSendEntityName = (entity: string): string => {
    const entityNames: {[key: string]: string} = {
        email: 'Email',
        phone: 'SMS',
    };

    return entityNames[entity] || '';
};
