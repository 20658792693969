import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {
    formatEntityName,
} from './Pages/EntityVerification/helpers/converters';
import { isLight } from '../common/helpers';
import MaskedTextField from './MaskedTextField';
import LoadingButton from './LoadingButton';
import { Theme, useTheme } from '@mui/material/styles';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../common/constants';
import { FunctionComponent, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = {
    container: {
        width: '440px',
    },
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28px',
        marginBottom: '25px',
    }),
    subtitleContainer: {
        display: 'flex',
        marginBottom: '25px',
        gap: '5px',
        flexWrap: 'wrap',
    },
    text: (
        theme: Theme,
        isInputTitle: boolean,
        isBottomInfo: boolean,
        shouldBeBold: boolean,
    ) => ({
        fontSize: isBottomInfo ? '12px' : '.9em',
        marginBottom: isInputTitle ? '10px' : '0px',
        fontWeight: shouldBeBold ? 'bold' : 'normal',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.common.white,
        '& a': {
            color: isLight(theme)
                ? theme.palette.text.primary
                : theme.palette.common.white,
            textDecoration: 'underline',
            '&:hover': {
                color: isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.common.white,
                textDecoration: 'underline',
            },
        },
    }),
    codeInput: {
        width: '100%',
        marginBottom: '10px',
    },
    bottomInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '5px',
        width: '100%',
        marginBottom: '35px',
    },
    bottomInfoIcon: {
        fontSize: '20px',
    },
    resendCodeButton: (disabled: boolean) => ({
        textDecoration: disabled ? 'none' : 'underline',
        cursor: disabled ? 'not-allowed' : 'pointer',
        '&:hover': {
            textDecoration: 'none',
        },
    }),
    verifyButton: (theme: Theme) => ({
        width: '100%',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            marginBottom: '30px',
        },
        whiteSpace: 'nowrap',
    }),
};

export interface Message {
    message: string;
    severity: 'error' | 'success';
}

export interface VerificationFormProps {
    entity: string;
    value: string;
    message?: Message;
    wait: boolean;
    timer: number;
    loading: boolean;
    disabled: boolean;
    handleCodeChange: (event: any) => void;
    handleSendNewCode: () => void;
    handleVerifyEntity: () => void;
    numericCode: boolean;
    setWait: (value: (((prevState: boolean) => boolean) | boolean)) => void;
    setTimer: (value: (((prevState: number) => number) | number)) => void;
    displayTitle: boolean;
}

const VerificationForm: FunctionComponent<VerificationFormProps> = ({
    entity,
    value,
    message,
    wait,
    timer,
    loading,
    disabled,
    handleCodeChange,
    handleSendNewCode,
    handleVerifyEntity,
    numericCode,
    setWait,
    setTimer,
    displayTitle,
}) => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet]),
    );

    const handleOtp = () => {
        const input = document.querySelector(
            'input[autocomplete="one-time-code"]',
        );

        if (!input) return;

        const ac = new AbortController();
        const form = input.closest('form');

        if (form) {
            form.addEventListener('submit', () => {
                ac.abort();
            });
        }

        navigator.credentials
        .get({
            otp: { transport: ['sms'] },
            signal: ac.signal,
        } as any)
        .then((otp) => {
            (input as HTMLInputElement).value = (otp as any).code;
            if (form) form.submit();
        })
        .catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        if ('OTPCredential' in window) {
            window.addEventListener('DOMContentLoaded', handleOtp);
        }

        return () => window.removeEventListener('DOMContentLoaded', handleOtp);
    }, []);

    useEffect(() => {
        let interval: any;

        if (wait) {
            interval = setInterval(() => {
                const timeLeft = timer - 1;

                if (timeLeft < 1) {
                    clearInterval(interval);
                    setWait(false);
                    setTimer(30);
                } else {
                    setTimer(timeLeft);
                }
            }, 1000);
        }

        return () => interval && clearInterval(interval);
        // eslint-disable-next-line
    }, [wait, timer]);

    return <Box sx={ styles.container }>
        { !isMobile && displayTitle && <Typography sx={ styles.title }>
            { `Verify your ${ formatEntityName(entity) }` }
        </Typography> }
        <Box sx={ styles.subtitleContainer }>
            <Typography sx={ styles.text(theme, false, false, false) }>
                { 'We\'ve sent a verification code to ' }
            </Typography>
            <Typography sx={ styles.text(theme, false, false, true) }>
                { value }
            </Typography>
        </Box>
        <Typography sx={ styles.text(theme, true, false, true) }>
            Verification code
        </Typography>
        { message && <Alert
            severity={ message.severity }
            iconMapping={{ error: <WarningIcon /> }}
            sx={{ overflow: 'hidden', whiteSpace: 'normal' }}
        >
            { message.message }
        </Alert> }
        { numericCode
            ? <MaskedTextField
                mask="999999"
                label="Verification code"
                id="verificationCode"
                type="text"
                name="one-time-code"
                autoComplete="one-time-code"
                inputProps={ { inputMode: 'numeric' } }
                arial-label="verificationCode"
                sx={ styles.codeInput }
                onChange={ handleCodeChange }
            />
            : <TextField
                label="Verification code"
                id="verificationCode"
                type="text"
                name="one-time-code"
                autoComplete="one-time-code"
                arial-label="verificationCode"
                sx={ styles.codeInput }
                onChange={ handleCodeChange }
            />
        }
        <Box sx={ styles.bottomInfoContainer }>
            <InfoIcon
                sx={ styles.bottomInfoIcon }
                fill={ isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.common.white
                }
            />
            <Typography sx={ styles.text(theme, false, true, false) }>
                { 'Didn\'t receive a code? ' }
            </Typography>
            <Typography
                sx={{
                    ...styles.text(theme, false, true, false),
                    ...styles.resendCodeButton(wait),
                }}
                onClick={ handleSendNewCode }
            >
                { `Send the new code ${ wait
                    ? 'in ' + timer + ' s'
                    : ''}`
                }
            </Typography>
        </Box>
        <LoadingButton
            loading={ loading }
            disabled={ loading || disabled }
            variant="contained"
            color="secondary"
            aria-label="verifyEntity"
            data-testid="btn-verify-entity"
            sx={ styles.verifyButton }
            onClick={ handleVerifyEntity }
        >
            {`Verify ${ formatEntityName(entity) }`}
        </LoadingButton>
    </Box>;
};

export default VerificationForm;
