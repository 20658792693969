import OfflineStore from '../OfflineStore';
import { ApiClient } from '../clients/ApiClient';
import {
    browserSupportsWebAuthn,
    startAuthentication,
    startRegistration,
} from '@simplewebauthn/browser';
import { platformHelper } from './platformHelper';

export function isBiometricLoginEnabled(): boolean {
    return !!OfflineStore.get(OfflineStore.ENABLE_BIOMETRIC_LOGIN);
}

export interface BiometricEnabledInput {
    consumerName: string;
    customerId: string;
    email: string;
}

export interface SaveBiometricResponse {
    completed: boolean;
    error?: string;
}

export async function toggleBiometricLogin(
    enabled: boolean,
    enabledInput?: BiometricEnabledInput,
): Promise<SaveBiometricResponse> {
    if (!enabled) {
        OfflineStore.set(OfflineStore.ENABLE_BIOMETRIC_LOGIN, false);

        return { completed: true };
    }

    if (enabled && enabledInput) {
        const { consumerName, email, customerId } = enabledInput;
        const regDeviceOptions = await ApiClient.request(
            '/getRegDeviceOptions',
            {
                body: {
                    input: {
                        consumerName,
                        domain: window.location.hostname,
                        customerId,
                        email,
                    },
                },
            });

        const attestationResponse = await startRegistration(
            regDeviceOptions,
        );

        const { data: { addCustomerAuthenticator }} = await ApiClient.request(
            '/verifyRegDevice',
            {
                body: {
                    input: {
                        currentChallenge: regDeviceOptions.challenge,
                        origin: window.location.origin,
                        data: attestationResponse,
                        domain: window.location.hostname,
                        customerId: customerId,
                    },
                },
            },
        );

        if (addCustomerAuthenticator?.completed) {
            OfflineStore.set(OfflineStore.ENABLE_BIOMETRIC_LOGIN, true);
        }

        return addCustomerAuthenticator;
    }

    return { completed: false, error: 'Undefined device registration info!' };
}

export interface BiometricAuthenticationResponse {
    customerId: string;
    credentialId: string;
}

export async function biometricAuthentication(): Promise<
    BiometricAuthenticationResponse | undefined
> {
    const { data: { customerAuthenticator }} = await ApiClient.request(
        '/getCustomerAuthenticator',
    );

    if (customerAuthenticator?.authenticator) {
        const options = await ApiClient.request(
            '/getAuthDeviceOptions',
            { body: {
                    domain: window.location.hostname,
                    credentialId: customerAuthenticator.
                        authenticator.
                        credentialId,
                }},
        );
        const assertionResponse = await startAuthentication(
            options,
            false,
        );
        const verifyData = await ApiClient.request(
            '/verifyAuthDevice',
            { body: {
                    startAuthResponse: assertionResponse,
                    challenge: options.challenge,
                    origin: window.location.origin,
                    domain: window.location.hostname,
                    authenticator: customerAuthenticator?.authenticator,
                }},
        );

        if (verifyData?.verified) {
            return {
                credentialId: customerAuthenticator.authenticator.credentialId,
                customerId: customerAuthenticator.customerId,
            };
        }

        return undefined;
    }

    return undefined;
}

export function biometricLoginAllowed(): boolean {
    return (platformHelper.isIos
        || platformHelper.isAndroid
    ) && browserSupportsWebAuthn();
}
